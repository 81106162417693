// 转款详情标题样式
.title-TextSty {
  font-size: 15px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
}

.dItemTwo {
  .ant-descriptions-item-label {
    margin-top: 5px;
  }
}